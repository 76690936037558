import React, { useState, useEffect } from 'react'
import { use } from 'react'
import { isSignedIn as isSignedFromCookie } from 'utils/cookies'

/*
 * This is a public page.
 */

import './ShopifyConnected.css'
import { getMe } from 'utils/api/users'
import { Plus } from 'lucide-react'
import { associateShopifyPassWithOrg } from 'utils/api/internal-apps'

const SelectCompanyRow = ({ company, onAssociate }) => {
  const firstTwoChars =
    company.name.length > 2
      ? `${company.name.substring(0, 1).toUpperCase()}${company.name.substring(1, 2).toLowerCase()}`
      : company.name.toUpperCase()

  return (
    <button
      className="select-company-button"
      onClick={(e) => onAssociate(e, company.id)}
    >
      <div>
        <span className="select-company-logo">{firstTwoChars}</span>
      </div>
      <div>
        <span className="select-company-name">{company.name}</span>
      </div>
    </button>
  )
}

const REDIRECT_TIME = 10

const ShopifyConnected = () => {
  const [initialLoading, setInitialLoading] = useState(true)
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [companyOptions, setCompanyOptions] = useState([])
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [timeTillRedirect, setTimeTillRedirect] = useState(0)
  const [associating, setAssociating] = useState(false)
  const [redirectingAfterSelecting, setRedirectingAfterSelecting] =
    useState(false)

  // Get query parameters
  const queryParams = new URLSearchParams(window.location.search)
  const shopifyPass = queryParams.get('shopify_pass')

  useEffect(() => {
    if (!shopifyPass || shopifyPass === '') {
      // Not set, just redirect with same query parameters
      window.location.href = `${window.location.origin}/?${queryParams.toString()}`
      return
    }

    if (isSignedFromCookie()) {
      // Call backend to confirm validate cookie
      getMe().then((response) => {
        console.log('response', response)
        if (response.success) {
          setIsSignedIn(true)
          setInitialLoading(false)
          setCompanyOptions(response.data.companiesWithAccess || [])
        } else {
          setIsSignedIn(false)
          setInitialLoading(false)
          setIsRedirecting(true) // Used for the time interval

          setTimeout(() => {
            // Redirect to /signup with query parameters
            window.location.href = `${window.location.origin}/signup?${queryParams.toString()}`
          }, REDIRECT_TIME * 1000)
        }
      })
    } else {
      setIsSignedIn(false)
      setInitialLoading(false)
      setIsRedirecting(true)

      setTimeout(() => {
        // Redirect to /signup with query parameters
        window.location.href = `${window.location.origin}/signup?${queryParams.toString()}`
      }, REDIRECT_TIME * 1000)
    }
  }, [])

  useEffect(() => {
    if (!isRedirecting) {
      return
    }

    const interval = setInterval(() => {
      setTimeTillRedirect((prevTime) => prevTime + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [isRedirecting])

  const onAssociate = (e, companyId) => {
    e.preventDefault()
    setAssociating(true)

    associateShopifyPassWithOrg({
      shopifyPass,
    }).then((response) => {
      setAssociating(false)
      if (response.success) {
        setRedirectingAfterSelecting(true)
        setTimeout(() => {
          // Redirect to /dashboard
          window.location.href = `${window.location.origin}/?successful=shopify-connected&${queryParams.toString()}`
        }, 5000)
      } else {
        window.alert(response.data.message)
      }
    })
  }

  if (!shopifyPass || shopifyPass === '') {
    return (
      <div>
        <p>Redirecting...</p>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="row shopify-connected-row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <h1 className="shopify-connected-title">Almost Done</h1>
          <p className="shopify-connected-text">
            Shopify has been successfully been connected and oauthorized.{' '}
          </p>
          <div className="shopify-pass-code-textbox-wrapper">
            <input
              type="text"
              className="form-control shopify-pass-code-textbox"
              value={shopifyPass}
              readOnly
            />
          </div>
          <p className="shopify-pass-code-help-text">
            Please copy the code above in the event something goes wrong.
          </p>
          <p className="shopify-connected-redirect">
            {initialLoading ? (
              <></>
            ) : isSignedIn ? (
              ''
            ) : (
              'We are going to redirect you to the sign in / sign up pages.'
            )}
          </p>
          {initialLoading ? (
            <div className="shopify-connected-redirect">
              <div className="spinner-border text-primary" role="status"></div>
              <p>Please give us a moment to look up a few details.</p>
            </div>
          ) : isSignedIn && redirectingAfterSelecting ? (
            <div className="shopify-connected-redirect">
              <div className="spinner-border text-primary" role="status"></div>
              <p>
                Redirecting in 5 seconds, or click{' '}
                <a
                  href={`${window.location.origin}/?${queryParams.toString()}`}
                >
                  here
                </a>
              </p>
            </div>
          ) : isSignedIn ? (
            <div>
              <h2 className="shopify-connected-select-title">
                Select a Company
              </h2>
              {(companyOptions || []).length > 0 && (
                <>
                  {companyOptions.map((company) => (
                    <SelectCompanyRow
                      key={company.id}
                      company={company}
                      onAssociate={onAssociate}
                    />
                  ))}
                  <p className="shopify-connected-or-text">or</p>
                </>
              )}
              <a
                className="shopify-connected-create-company-button"
                href={`/create-company?${queryParams.toString()}`}
              >
                <div>
                  <span className="select-company-logo">
                    <Plus size={24} />
                  </span>
                </div>
                <div>
                  <span className="shopify-connected-create-company-text">
                    Create a new company
                  </span>
                </div>
              </a>
            </div>
          ) : (
            <div className="shopify-connected-redirect">
              <div className="spinner-border text-primary" role="status"></div>
              <p>
                Redirecting in {REDIRECT_TIME - timeTillRedirect} seconds, or
                click{' '}
                <a
                  href={`${window.location.origin}/signup?${queryParams.toString()}`}
                >
                  here
                </a>
              </p>
            </div>
          )}
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  )
}

export default ShopifyConnected
