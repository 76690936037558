import { useState, useEffect, useRef } from 'react'

import FilterCard from './FilterCard'

import './Filters.css'
import { Filter } from 'lucide-react'

const FilterButton = ({
  children,
  showFilter,
  setShowFilter,
  filters,
  setFilters,
  onToggle,
  options,
}) => {
  const filterRef = useRef(null)

  const onClickOutsideOfFilters = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      console.log('click outside of filters', filterRef)
      setShowFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideOfFilters)
    return () => {
      document.removeEventListener('mousedown', onClickOutsideOfFilters)
    }
  }, [])

  return (
    <div>
      <button
        type="button"
        className="btn btn-light btn-lg me-2"
        onClick={(e) => {
          e.preventDefault()
          console.log('showFilter on click', showFilter, filterRef.current)
          // if (filterRef.current) {
          //   // If set, then clicking outside of button
          //   return
          // }
          setShowFilter(!showFilter)
        }}
      >
        <Filter />
        {/* {Object.keys(filters).length > 0 && <> {Object.keys(filters).length}</>} */}
      </button>
      <>
        {showFilter && (
          <div ref={filterRef}>
            <FilterCard
              options={options}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        )}
      </>
    </div>
  )
}

export default FilterButton
