import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SettingsPage from '../SettingsPage'
import Textbox from 'components/Textbox/Textbox'
import Button from 'components/Button/Button'

import { getMe } from 'utils/api/users'
import { createOrganization } from 'utils/api/organizations'

import { setCompanyId, setCompaniesAvailable } from 'utils/cookies'

const SettingsCreateCompanyPage = () => {
  let navigate = useNavigate()

  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()

    setError('')
    if (name === '') {
      setError('Name is required')
      return
    }

    setError('')
    setLoading(true)
    createOrganization({
      name,
    }).then((response) => {
      setLoading(false)
      if (response.success) {
        setCompanyId(response.data.company.id)
        getMe().then((response) => {
          if (response.success) {
            setCompaniesAvailable(response.data.companiesWithAccess)
          }
          // reload page
          window.location.reload()
        })
      } else {
        setError(response.data.message)
      }
    })
  }

  if (redirect !== '') {
    console.log('redirect', redirect)
    navigate(redirect)
  }

  return (
    <SettingsPage loading={false}>
      <form onSubmit={onSubmit}>
        <Textbox
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          textboxStyle={{ maxWidth: 300 }}
          error={error}
        />
        <div className="settings-save-btn-wrapper">
          <Button loading={loading}>Create</Button>
        </div>
      </form>
    </SettingsPage>
  )
}

export default SettingsCreateCompanyPage
