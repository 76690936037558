import { useState, useEffect, useRef } from 'react'
import { Check, CircleCheck, Plus, Trash } from 'lucide-react'
import { listPartners } from 'utils/api/partners'

const shouldHide = (optionKey, hideIf, filters) => {
  console.log('shouldHide', optionKey, hideIf, filters)
  if (!hideIf) {
    return false
  }

  for (const [key, details] of Object.entries(hideIf)) {
    if (filters[key] === details.value && details.operator === 'eq') {
      return true
    } else if (filters[key] !== details.value && details.operator === 'neq') {
      return true
    }
  }
  return false
}

const EndpointSelection = ({ option, setCurrentValues, currentValues }) => {
  const [showResults, setShowResults] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [results, setResults] = useState([])

  const onSearch = async () => {
    if (option.endpoint == 'get_partners') {
      if (!searchValue || searchValue === '') {
        setResults([])
        return
      }

      const response = await listPartners({ name: searchValue })
      if (response && response.success) {
        console.log('response.data.partners', response.data.partners)
        setResults(response.data.partners || [])
        setShowResults(true)
      } else {
        return
      }
    }
  }

  useEffect(() => {
    onSearch()
  }, [searchValue])

  const onSelectOption = (e, id, val) => {
    e.preventDefault()
    console.log('onSelectOption', id, val)
    // Confirm no duplicates with id
    if (currentValues[id]) {
      return
    }

    setCurrentValues({
      ...currentValues,
      [id]: val,
    })
  }

  // Handle clicking outside of  endpoint-selection-results
  useEffect(() => {
    const onClickOutside = (e) => {
      if (e.target.className !== 'endpoint-selection-results-item') {
        setShowResults(false)
      }
    }

    document.addEventListener('click', onClickOutside)
    return () => {
      document.removeEventListener('click', onClickOutside)
    }
  })
  return (
    <div className="filter-options-value">
      <input
        type="text"
        className="form-control filter-options-value"
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={option.placeholder}
      />
      {showResults && (
        <div className="endpoint-selection-results">
          {results.map((result) => {
            const alreadySelected = currentValues[result.id]
            return (
              <div
                className={`endpoint-selection-results-item ${alreadySelected ? 'endpoint-selection-results-item-selected' : ''}`}
                onClick={(e) => onSelectOption(e, result.id, result.name)}
              >
                {alreadySelected ? <Check /> : <Plus />}
                <p>{result.name}</p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const FilterCardOption = ({
  options,
  optionKey,
  filters,
  option,
  setFilters,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState({})

  console.log('option', option, 'optionKey', optionKey, 'filters', filters)
  const currentValues = filters[optionKey] || {}
  if (!option) {
    return null
  }
  if (shouldHide(optionKey, option.hideIf, filters)) {
    return null
  }

  const onChange = (e) => {
    console.log('onChange', e.target.value)
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [optionKey]: e.target.value,
      }
    })
  }

  const setCurrentValues = (newValues) => {
    setFilters({
      ...filters,
      [optionKey]: newValues,
    })
  }

  const onRemoveSelected = (e, id) => {
    e.preventDefault()
    const newValues = { ...currentValues }
    delete newValues[id]
    setCurrentValues(newValues)
  }

  return (
    <>
      <div className="filter-options-wrapper">
        <p className="filter-options-key">{option.label}</p>
        <p className="filter-options-operator">=</p>
        {option.type == 'set_dropdown' ? (
          <select
            className="form-control filter-options-value"
            onChange={onChange}
          >
            {Object.keys(option.acceptedValues || {}).map((optionValue) => {
              return (
                <option value={optionValue}>
                  {option.acceptedValues[optionValue]}
                </option>
              )
            })}
          </select>
        ) : option.type === 'endpoint_selection' ? (
          <EndpointSelection
            option={option}
            setFilters={setFilters}
            setCurrentValues={setCurrentValues}
            currentValues={currentValues}
          />
        ) : null}
      </div>
      {option.type === 'endpoint_selection' && (
        <div>
          {Object.keys(currentValues).length === 0 ? (
            <p>No partners selected</p>
          ) : (
            <>
              {Object.keys(currentValues).map((id) => {
                return (
                  <button
                    className="filter-options-value-selected-value badge rounded-pill badge-subtle-primary"
                    onClick={(e) => onRemoveSelected(e, id)}
                  >
                    {currentValues[id]} <Trash size={12} />
                  </button>
                )
              })}
            </>
          )}
        </div>
      )}
    </>
  )
}

// filters is {} with key: value pairs
const FilterCard = ({ filters, setFilters, options }) => {
  if (!options || Object.keys(options).length === 0) {
    return null
  }

  console.log('filters', filters)
  console.log('options', options)

  return (
    <div className="card filter-card">
      <div className="card-body">
        {Object.keys(options || {}).map((optionKey) => {
          console.log('optionKey', optionKey)
          return (
            <FilterCardOption
              key={optionKey}
              optionKey={optionKey}
              option={options[optionKey]}
              options={options}
              filters={filters}
              setFilters={setFilters}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FilterCard
