import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Circle, CircleCheckBig } from 'lucide-react'
import SharedOnboardingPage from './SharedOnboardingPage'
import OnboardingPageNextButtons from './OnboardingPageNextButtons'

import { getMe } from 'utils/api/users'
import { onboardingSaveQuestionnaire } from 'utils/api/organizations'

import './OnboardingQuestionairePage.css'

const QuestionnaireOption = ({
  children,
  option,
  selectedOptions,
  onOptionSelected,
}) => {
  const selected = selectedOptions.includes(option)
  return (
    <div
      className={`questionnaire-option ${selected ? 'questionnaire-option-selected' : ''}`}
      onClick={(e) => onOptionSelected(option)}
    >
      <div className="questionnare-option-icon-col">
        {selected ? (
          <CircleCheckBig className="questionnaire-option-icon" />
        ) : (
          <Circle className="questionnaire-option-icon" />
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}

const OnboardingQuestionairePage = () => {
  let navigate = useNavigate()
  const [initialLoading, setInitialLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [currentCompany, setCurrentCompany] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        setSelectedOptions(
          response.data.company.questionnaireSelectedOptions || []
        )
        setCurrentCompany(response.data.company)
        setInitialLoading(false)
      } else {
        window.alert(response.data.message)
      }
    })
  }, [])

  const onOptionSelected = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(
        selectedOptions.filter((selectedOption) => selectedOption !== option)
      )
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const onNext = (e) => {
    e.preventDefault()

    setLoading(true)
    onboardingSaveQuestionnaire({
      selectedOptions,
    }).then((response) => {
      if (response.success) {
        if (currentCompany.hideBilling) {
          navigate('/onboarding/apps')
        } else {
          navigate('/onboarding/plans')
        }
      } else {
        window.alert(response.data.message)
        setLoading(false)
      }
    })
  }

  if (initialLoading) {
    return (
      <SharedOnboardingPage title="Let's get to know you better" step={1}>
        <div className="onboarding-title-loading"></div>
        <div className="onboarding-title-subtext-loading"></div>
        <div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
          <div className="questionnaire-option-loading">
            <div className="questionnaire-option-loading-icon"></div>
            <div className="questionnaire-option-loading-text"></div>
          </div>
        </div>
      </SharedOnboardingPage>
    )
  }

  return (
    <SharedOnboardingPage title="Let's get to know you better" step={1}>
      <h2 className="onboarding-section-title">
        What best describes your use case?
      </h2>
      <p className="text-muted">Select all that apply.</p>

      <div>
        <QuestionnaireOption
          option="supplier_connecting_with_retailers"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm a Supplier / Brand looking to partner with retailers.
        </QuestionnaireOption>
        <QuestionnaireOption
          option="supplier_connecting_with_edi_retailers"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm a Supplier / Brand looking to partner with retailers using EDI.
        </QuestionnaireOption>
        <QuestionnaireOption
          option="retailer_modern_platform"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm a Retailer looking to manage brands and I am using a more modern
          platform (Ex. Shopify, WooCommerce, etc.).
        </QuestionnaireOption>
        <QuestionnaireOption
          option="retailer_edi"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm a Retailer looking to manage brands and I want to connect with
          EDI.
        </QuestionnaireOption>
        <QuestionnaireOption
          option="marketplace"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm managing a marketplace.
        </QuestionnaireOption>
        <QuestionnaireOption
          option="retailer_no_partnerships"
          selectedOptions={selectedOptions}
          onOptionSelected={onOptionSelected}
        >
          I'm looking for products for my e-commerce store, but no existing
          partnerships.
        </QuestionnaireOption>
      </div>
      <OnboardingPageNextButtons>
        <button className="btn btn-primary" onClick={onNext} disabled={loading}>
          Next
        </button>
      </OnboardingPageNextButtons>
    </SharedOnboardingPage>
  )
}

export default OnboardingQuestionairePage
